@import '../../node_modules/normalize.css/normalize.css';
@import '../../node_modules/socicon/css/socicon.css';

$font-body: 'Space Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
  Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

$color-foreground: var(--color-foreground);
$color-background: var(--color-background);
$color-primary: #ec417b;

::selection {
  background: mix($color-primary, transparent, 20%);
}

html {
  --color-foreground: #0f1113;
  --color-background: #ffffff;
}

@media (prefers-color-scheme: dark) {
  html {
    --color-background: #0f1113;
    --color-foreground: #ffffff;
  }
}

body {
  background: $color-background;
  color: $color-foreground;
  font-family: $font-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.title {
  font-size: 48px;
  text-transform: lowercase;
  margin: 0;
  opacity: 0;
  margin: auto;
  text-align: center;
  letter-spacing: 0.1em;
}

.socialLinks {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 16px 0;
  justify-content: center;
  li {
    padding: 14px;
    font-size: 16px;
    opacity: 0;
    transform: translate3d(0, 2em, 0);
  }
  a {
    opacity: 0.3;
    color: $color-foreground;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    &:hover {
      opacity: 1;
      color: $color-primary;
    }
  }
}

.ready {
  .title {
    animation: titleIn 1s ease-out forwards 0.2s;
  }
  .socialLinks {
    li {
      animation: socialLinkIn 0.5s ease-out forwards 0.8s;
      @for $i from 0 through 9 {
        &:nth-child(#{$i + 1}) {
          animation-delay: #{0.6 + 0.1 * $i}s;
        }
      }
    }
  }
}

@keyframes titleIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes socialLinkIn {
  0% {
    opacity: 0;
    transform: translate3d(0, 2em, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
